<template>
  <div>
    <ds-header title="图片上传"></ds-header>
    <div class="main">
      <div class="uploadClass">
        <a-upload-dragger
          name="file"
          style="width: 200px"
          :multiple="false"
          :action="IMG_API + '/oss/files'"
          :showUploadList="false"
          @change="handleChange"
          :before-upload="imgBeforeUpload"
        >
          <p class="ant-upload-drag-icon">
            <a-icon type="inbox" />
          </p>
          <p class="ant-upload-text">
            单击或拖动文件到此区域以上载
          </p>
          <p class="ant-upload-hint">
            <span><span style="color: red; margin-right: 5px">*</span>注：图片必须是688*220，大小不超过500KB</span>
          </p>
        </a-upload-dragger>
      </div>
      <div class="inputClass">
        <div v-show="imgUrl">
          <img width="668px" height="220px"  :src="imgUrl"/>
        </div>
        <a-input-search v-model="imgUrl"  size="large" @search="toCopy">
          <a-button slot="enterButton">
            复制
          </a-button>
        </a-input-search>
      </div>
    </div>
  </div>
</template>

<script>
import { IMG_API } from "@/config";
export default {
  name: "uploadImgPage",
  data() {
    return {
      IMG_API: IMG_API,
      imgUrl: '',
    };
  },
  created() {
  },
  methods: {
    handleChange(info) {
      const status = info.file.status;
      if (status === 'done') {
        this.imgUrl = info.file.response.redirect_uri
      } else if (status === 'error') {
        this.$message.error("上传失败！");
        this.imgUrl = ''
      } else {
        this.imgUrl = ''
      }
    },
    //上传文件之前校验图片大小
    imgBeforeUpload(file) {
      const that = this
      let index = file.name.lastIndexOf(".");
      let type = file.name.substring(index + 1, file.name.length);
      const isJPG = type === "png" || type === "jpg";
      if (!isJPG) {
        that.$message.error("上传图片只能是jpg、png格式!");
        return new Promise(function (resolve, reject) {return reject});
      }
      if (file.size/1024 > 500) {
        that.$message.error("上传图片不能大于500KB!");
        return new Promise(function (resolve, reject) {return reject});
      }
      const isSize = new Promise(function (resolve, reject) {
        const URL = window.URL || window.webkitURL;
        const img = new Image();
        img.onload = function () {
          // const proportion = that.reductionTo(img.width, img.height);
          const valid = img.width === 688 && img.height === 220
          if (valid) {
            resolve();
          } else {
            that.$message.error("上传图片必须是688*220");
            return new Promise(function (resolve, reject) {return reject});
          }
          // valid ? resolve() : reject();
        };
        img.src = URL.createObjectURL(file);
      }).then(
        () => {
          return file;
        },
        () => {
          that.$message.error("图片不规范，请按要求上传");
          return reject();
        }
      );
      return isJPG && isSize;
    },
    toCopy() {
      if (this.imgUrl) {
        const url = this.imgUrl
        let oInput = document.createElement('input')
        oInput.value = url
        document.body.appendChild(oInput)
        oInput.select() // 选择对象;
        document.execCommand('Copy') // 执行浏览器复制命令
        this.$message.success("复制成功！")
        oInput.remove()
      } else {
        this.$message.error("值为空！")
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .uploadClass {
    width: 800px;
    height: 400px;
    margin-bottom: 40px;
  }
  .inputClass {
    width: 800px;
  }
}
</style>
